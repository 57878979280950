<template>
  <div class="activityTask">
    <el-card>
      <el-button type="primary" @click="add_new_item" style="margin-bottom: 20px">
        <i class="el-icon-plus"></i> 添加
      </el-button>
      <el-table v-loading="loading" :data="tableData" border style="width: 100%">
        <el-table-column fixed prop="id" label="ID" width="70"> </el-table-column>
        <el-table-column prop="name" label="任务图">
          <template slot-scope="scope">
            <el-image :src="scope.row.img" :preview-src-list="[scope.row.img]"></el-image>
          </template>
</el-table-column>
<el-table-column prop="name" label="任务名称"> </el-table-column>
<el-table-column prop="desc" label="任务说明"> </el-table-column>
<el-table-column prop="reward_type" label="奖励类型">
    <template slot-scope="scope">
            <span>{{ getReward_type(scope.row.reward_type) }}</span>
          </template>
</el-table-column>
<el-table-column prop="reward_num" label="奖励数量"> </el-table-column>
<el-table-column prop="repeat_type" label="重复类型">
    <template slot-scope="scope">
            <span>{{ getRepeat_type(scope.row.repeat_type) }}</span>
          </template>
</el-table-column>
<el-table-column prop="target_times" label="目标次数">
</el-table-column>
<el-table-column prop="status" label="任务状态">
    <template slot-scope="scope">
            <span style="color: #67c23a" v-if="scope.row.status == 1">{{
              getStatusToDetail(scope.row.status)
            }}</span>
            <span style="color: #f56c6c" v-else-if="scope.row.status == 2">{{
              getStatusToDetail(scope.row.status)
            }}</span>
            <span style="color: #e6a23c" v-else-if="scope.row.status == 3">{{
              getStatusToDetail(scope.row.status)
            }}</span>
          </template>
</el-table-column>
<el-table-column fixed="right" label="操作" width="120">
    <template slot-scope="scope">
            <el-popover
              v-if="scope.row.status == 1"
              style="margin: 0 10px"
              placement="top"
              trigger="click"
              width="220"
              v-model="scope.row.visible"
            >
              <div class="del_suer_div">
                <div class="left_content">
                  <span class="el-icon-info icon"></span>
                </div>
                <div class="right_content">
                  <div class="tishi_big_font">提示</div>
                  <div class="tishi_small_font">
                    <span class="error">{{ scope.row.stop_text }}</span>
                  </div>
                </div>
              </div>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="scope.row.visible = false"
                  >取消</el-button
                >
                <el-button type="primary" size="mini" @click="stop_use(scope)"
                  >确定</el-button
                >
              </div>
              <el-button
                @click="showRowVisible(scope)"
                slot="reference"
                type="text"
                size="small"
                >停用</el-button
              >
            </el-popover>
            <el-popover
              v-else-if="scope.row.status == 2"
              style="margin: 0 10px"
              placement="top"
              width="220"
              v-model="scope.row.visible"
            >
              <!--<div class="del_suer_div">
                <div class="left_content">
                  <span class="el-icon-info icon"></span>
                </div>
                <div class="right_content">
                  <div class="tishi_big_font">任务停用后不可使用</div>
                  <div class="tishi_small_font">
                    <span class="error">任务预计在XX年XX月XX日正式停用，</span
                    >是否需要停用该任务？
                  </div>
                </div>
              </div>-->
              <p>确定启用？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="scope.row.visible = false"
                  >取消</el-button
                >
                <el-button type="primary" size="mini" @click="start_use(scope)"
                  >确定</el-button
                >
              </div>
              <el-button
                @click="showRowVisible(scope)"
                slot="reference"
                type="text"
                size="small"
                >启用</el-button
              >
            </el-popover>
            <span v-else-if="scope.row.status == 3"> -- </span>

            <el-button
              v-if="scope.row.status == 2"
              @click="startRestUse(scope.row)"
              slot="reference"
              type="text"
              size="small"
              >复用</el-button
            >
            <el-button @click="editDetail(scope.row)" type="text" size="small"
              >修改</el-button
            >
          </template>
</el-table-column>
</el-table>
<el-pagination style="margin-top: 15px; text-align: center" background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="page" :page-sizes="[20, 30, 40, 50]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper"
    :total="total">
</el-pagination>
</el-card>

<el-dialog :title="dialogTitle" :visible.sync="dialogVisible" @close="clearDialogOldData('ruleForm')" :close-on-click-modal="false" width="40%">
    <el-alert v-show="ruleForm.errMessage && ruleForm.errMessage.length > 0" :title="ruleForm.errMessage" :closable="false" type="warning" style="margin-bottom: 10px">
    </el-alert>
    <el-form v-loading="ruleForm.loading" label-position="right" label-width="160px" :model="ruleForm">
        <el-row>
            <el-form-item label="任务名称：">
                <el-input v-model="ruleForm.name" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="任务图片：">
                <uploadImg dir="image/platform_page" id="up" @url="imgUrl" v-model="ruleForm.img"></uploadImg>
            </el-form-item>
            <el-form-item label="任务说明：">
                <el-input v-model="ruleForm.desc" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="奖励类型：">
                <el-radio-group :disabled="ruleForm.id && ruleForm.id.length > 0 ? true : false" v-model="ruleForm.reward_type">
                    <el-radio :label="item.type" v-for="(item, index) in reward_type_list" :key="index">{{ item.name }}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="奖励数量：">
                <el-input :disabled="ruleForm.id && ruleForm.id.length > 0 ? true : false" v-model="ruleForm.reward_num" placeholder="请输入" type="number"></el-input>
            </el-form-item>
            <el-form-item label="重复类型：">
                <el-radio-group v-model="ruleForm.repeat_type" :disabled="ruleForm.id && ruleForm.id.length > 0 ? true : false">
                    <el-radio :label="item.type" v-for="(item, index) in repeat_type_list" :key="index">{{ item.name }}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="目标次数：">
                <el-input :disabled="ruleForm.id && ruleForm.id.length > 0 ? true : false" v-model="ruleForm.target_times" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="做任务文案：">
                <el-input v-model="ruleForm.copywriting" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="任务权重：">
                <el-input v-model="ruleForm.weight" placeholder="请输入" type="number"></el-input>
            </el-form-item>
            <el-form-item label="任务状态：">
                <el-radio-group v-model="ruleForm.status" :disabled="dialogTitle === '编辑任务'">
                    <el-radio :label="item.value" v-for="(item, index) in status_list" :key="index">{{ item.label }}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="领取奖励后继续显示：">
                <el-radio-group v-model="ruleForm.is_complete_view">
                    <el-radio :label="item.value" v-for="(item, index) in is_complete_show_list" :key="index">{{ item.label }}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="做任务安卓跳转链接：">
                <el-input v-model="ruleForm.android_redirect_url" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="做任务ios跳转链接：">
                <el-input v-model="ruleForm.ios_redirect_url" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="跳转类型：">
                <el-select v-model="ruleForm.redirect_type" placeholder="请选择">
                    <el-option v-for="item in redirect_type_list" :key="item.type" :label="item.name" :value="item.type">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="携带参数：">
                <el-input v-model="ruleForm.bind_params" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="跳转标题：">
                <el-input v-model="ruleForm.redirect_title" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="版本号限制：">
                <el-radio-group v-model="ruleForm.version_limit_type">
                    <el-radio :label="item.type" v-for="(item, index) in compare_type_list" :key="index">{{ item.name }}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="版本号：">
                <el-input v-model="ruleForm.version" placeholder="请输入"></el-input>
            </el-form-item>
        </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog('dialogVisible')">取消</el-button>
        <el-button type="primary" @click="onSubmit('ruleForm')">提交</el-button>
      </span>
</el-dialog>
</div>
</template>
<script>
    let common = JSON.parse(localStorage.getItem("common"));
    import uploadImg from "@/components/upLoad/index.vue";
    import * as api from "@/config/api";
    export default {
        components: {},
        data() {
            return {
                reward_type_list: common.reward_type_list,
                repeat_type_list: common.repeat_type_list,
                redirect_type_list: common.redirect_type_list,
                compare_type_list: common.compare_type_list,
                // 弹出form
                ruleForm: {
                    name: "",
                    img: "",
                    id: "",
                    desc: "",
                    reward_type: "",
                    reward_num: "",
                    repeat_type: "",
                    target_times: "",
                    copywriting: "",
                    weight: "",
                    status: "2",
                    is_complete_view: "",
                    android_redirect_url: "",
                    ios_redirect_url: "",
                    redirect_type: "",
                    bind_params: "",
                    redirect_title: "",
                    version_limit_type: "",
                    version: "",
                    mark: "",
                    loading: false,
                    // 错误提示消息
                    errMessage: "",
                },
                // 弹出规则
                //   rules: {
                //     topic_name: [
                //       { required: true, message: "请输入专题名称", trigger: "blur" },
                //     ],
                //     remark: [
                //       { required: true, message: "请输入主题描述", trigger: "blur" },
                //     ],
                //   },
                // 状态列表
                status_list: [{
                    value: "1",
                    label: "启用",
                }, {
                    value: "2",
                    label: "停用",
                }, {
                    value: "3",
                    label: "待停用",
                }, ],
                is_complete_show_list: [{
                    value: "1",
                    label: "显示",
                }, {
                    value: "2",
                    label: "不显示",
                }, ],
                // 表格数据
                tableData: [],
                // 弹出显示
                dialogVisible: false,
                // 加载中
                loading: false,
                page: 1,
                pagesize: 20,
                total: undefined,
                // 彈出快的title
                dialogTitle: "",
            };
        },
        components: {
            uploadImg,
        },
        created() {
            this.getListData();
        },
        mounted() {},
        methods: {
            //  重置表单信息
            clearFormData() {
                this.ruleForm = {
                    name: "",
                    img: "",
                    id: "",
                    desc: "",
                    reward_type: "",
                    reward_num: "",
                    repeat_type: "",
                    target_times: "",
                    copywriting: "",
                    weight: "",
                    status: "2",
                    is_complete_view: "",
                    android_redirect_url: "",
                    ios_redirect_url: "",
                    redirect_type: "",
                    bind_params: "",
                    redirect_title: "",
                    version_limit_type: "",
                    version: "",
                    mark: "",
                    loading: false,
                    // 错误提示消息
                    errMessage: "",
                };
            },
            imgUrl(val) {
                this.ruleForm.img = val;
            },
            // 关闭弹窗 传key
            closeDialog(key) {
                this[key] = false;
            },
            // 打开弹窗 传key
            openDialog(key) {
                this[key] = true;
            },
            //提交  key属于那个formData
            onSubmit(key) {
                this[key].loading = true;
                if (key === "ruleForm") {
                    let {
                        topic_name,
                        remark,
                        id
                    } = this[key];
                    let param = this.ruleForm;
                    delete param.loading;
                    delete param.errMessage;
                    if (!id) {
                        // 新增
                        api.addActivityTaskRow(
                            param,
                            (res) => {
                                this.$message({
                                    message: "新增成功！",
                                    type: "success",
                                });
                                this[key].loading = false;
                                this.clearFormData();
                                this.dialogVisible = false;
                                this.getListData();
                            },
                            (err) => {
                                this[key].loading = false;
                                this[key].errMessage = err.msg || "未知错误";
                                setTimeout(() => {
                                    this[key].errMessage = "";
                                }, 2000);
                            }
                        );
                    } else {
                        // 编辑
                        api.updateActivityTaskRow(
                            param,
                            (res) => {
                                this.$message({
                                    message: "编辑成功！",
                                    type: "success",
                                });
                                this[key].loading = false;
                                this.dialogVisible = false;
                                this.clearFormData();
                                this.getListData();
                            },
                            (err) => {
                                this[key].loading = false;
                                this[key].errMessage = err.msg || "未知错误";
                                setTimeout(() => {
                                    this[key].errMessage = "";
                                }, 2000);
                            }
                        );
                    }
                }
            },
            // 获取数据列表
            getListData() {
                let param = {};
                this.loading = true;
                param.page = this.page;
                param.pagesize = this.pagesize;
                api.getActivityTaskList(
                    param,
                    (res) => {
                        this.loading = false;
                        if (!res.data || !res.data.list) {
                            this.total = 0;
                            this.tableData = [];
                            return;
                        }
                        let list = res.data.list;
                        this.total = Number(res.data.count);
                        list.forEach((item, value) => {
                            item.visible = false;
                        });
                        this.tableData = res.data.list;
                    },
                    (err) => {
                        this.loading = false;
                    }
                );
            },
            // 查看详情
            editDetail(e) {
                let id = e.id;
                if (!id) {
                    this.$message({
                        message: "沒有id",
                        type: "error",
                    });
                    return;
                }
                this.clearFormData();
                this.ruleForm.loading = true;
                this.openDialog("dialogVisible");
                api.getActivityTaskRow({
                        id
                    },
                    (res) => {
                        this.ruleForm.loading = false;
                        this.dialogTitle = "编辑任务";
                        for (const key in res.data) {
                            this.ruleForm[key] = res.data[key];
                        }
                    },
                    (err) => {
                        this.ruleForm.loading = false;
                        this.ruleForm.errMessage = err.msg || "未知错误";
                        setTimeout(() => {
                            this.ruleForm.errMessage = "";
                        }, 2000);
                    }
                );
            },
            // 停止使用
            stop_use(e) {
                let item = e.row;
                api.updateActivityTaskStatus({
                        id: item.id,
                        status: 2,
                    },
                    (res) => {
                        this.tableData[e.$index].visible = false;
                        this.$message({
                            message: "修改成功！",
                            type: "success",
                        });
                        this.getListData();
                    },
                    (err) => {
                        this.$message({
                            message: "修改失败！",
                            type: "success",
                        });
                        this.tableData[e.$index].visible = false;
                    }
                );
            },
            // 启用
            start_use(e) {
                let item = e.row;
                api.updateActivityTaskStatus({
                        id: item.id,
                        status: 1,
                    },
                    (res) => {
                        this.tableData[e.$index].visible = false;
                        this.$message({
                            message: "修改成功！",
                            type: "success",
                        });
                        this.getListData();
                    },
                    (err) => {
                        this.$message({
                            message: "修改失败！",
                            type: "success",
                        });
                        this.tableData[e.$index].visible = false;
                    }
                );
            },
            // 复用
            startRestUse(e) {
                let id = e.id;
                if (!id) {
                    this.$message({
                        message: "沒有id",
                        type: "error",
                    });
                    return;
                }
                this.clearFormData();
                this.ruleForm.loading = true;
                this.openDialog("dialogVisible");
                api.getActivityTaskRow({
                        id
                    },
                    (res) => {
                        this.ruleForm.loading = false;
                        this.dialogTitle = "添加任务";
                        for (const key in res.data) {
                            this.ruleForm[key] = res.data[key];
                        }
                        this.ruleForm.status = '1';
                        this.ruleForm.id = '';
                    },
                    (err) => {
                        this.ruleForm.loading = false;
                        this.ruleForm.errMessage = err.msg || "未知错误";
                        setTimeout(() => {
                            this.ruleForm.errMessage = "";
                        }, 2000);
                    }
                );
            },
            // 显示确认框
            showRowVisible(e) {
                setTimeout(() => {
                    this.tableData[e.$index].visible = true;
                }, 0);
            },
            // 根据状态码显示对应的内容
            getStatusToDetail(status) {
                if (status === undefined) return "";
                if (status == 1) {
                    return "已启用";
                } else if (status == 2) {
                    return "已停用";
                } else if (status == 3) {
                    return "待停用";
                } else {
                    return "其他";
                }
            },
            getRepeat_type(type) {
                if (this.repeat_type_list == undefined) {
                    return
                }
                if (type === undefined || type === null) {
                    return "";
                }
                return this.repeat_type_list[
                    this.repeat_type_list.findIndex((w) => w.type == type)
                ].name;
            },
            getReward_type(type) {
                if (this.reward_type_list == undefined) {
                    return
                }
                if (type === undefined || type === null) {
                    return "";
                }
                return this.reward_type_list[
                    this.reward_type_list.findIndex((w) => w.type == type)
                ].name;
            },
            handleSizeChange(e) {
                this.pagesize = e;
                this.getListData();
            },
            handleCurrentChange(e) {
                this.page = e;
                this.getListData();
            },
            // 添加一个新的
            add_new_item() {
                this.dialogVisible = true;
                this.dialogTitle = "添加任务";
                this.clearFormData();
            },
            clearDialogOldData(key) {
                if (key === "ruleForm") {
                    this[key] = {
                        topic_name: "",
                        remark: "",
                        id: "",
                        loading: false,
                        // 错误提示消息
                        errMessage: "",
                    };
                }
            },
        },
    };
</script>
<style lang="scss">
    .activityTask {
        padding: 0 20px;
        text-align: left;
    }
    
    .del_suer_div {
        display: flex;
        margin-bottom: 10px;
        .left_content {
            width: 30px;
            .icon {
                color: #e6a23c;
                font-size: 16px;
            }
        }
        .right_content {
            width: calc(100% - 30px);
            .tishi_big_font {
                font-size: 14px;
                font-weight: 700;
                color: #333;
            }
            .tishi_small_font {
                margin-top: 5px;
                font-size: 12px;
                .error {
                    color: #f56c6c;
                }
            }
        }
    }
    
    .go_down_model {
        display: inline-block;
        margin-bottom: 10px;
    }
    
    .input_down_tishi {
        position: absolute;
        bottom: 10px;
        transform: translate(0, 100%);
        font-size: 12px;
        color: #999;
    }
    
    .tb_pinlv_tishi {
        position: absolute;
        top: 50%;
        transform: translate(100%, -50%);
        right: -5px;
        font-size: 12px;
    }
</style>